import { render, staticRenderFns } from "./cclass.vue?vue&type=template&id=4e7a7024&scoped=true"
import script from "./cclass.vue?vue&type=script&lang=js"
export * from "./cclass.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e7a7024",
  null
  
)

export default component.exports